<template>
	<div>
		<b-row>
			<b-col cols="12">
				<b-form
					@submit.prevent="submit"
					@keydown="clearError($event.target.name)"
					@change="clearError($event.target.name)">
					<b-row>
						<b-col cols="12">
							<b-form-group>
								<label
									for="countryName"
									class="label">{{ translate('country') }} &nbsp;
									<span class="text-danger">*</span></label>

								<select
									id="countryName"
									v-model="form.country"
									name="country"
									class="form-control">
									<option
										v-for="c in countries"
										:key="c.attributes.code"
										:value="c.attributes.code">
										{{ translate(c.attributes.code.toLowerCase()) }}
									</option>
								</select>
							</b-form-group>
							<input-text
								id="mobile_number"
								:label="translate('mobile_number')"
								:setter-value="form.mobile_number"
								:errors="errors.errors['mobile_number']"
								:required="true"
								type="number"
								@dataChanged="form.mobile_number = $event" />
							<b-form-group>
								<label
									for="type"
									class="label">
									{{ translate('types') }}
									<span
										v-b-tooltip.hover
										:title="translate('credit_types_info')"
										class="text-muted">
										<i class="fas fa-lg fa-info-circle" />
									</span>
									&nbsp;
									<span class="text-danger">*</span>
								</label>

								<select
									id="type"
									v-model="form.type"
									:class="hasError('type') ? 'is-invalid' : ''"
									type="text"
									name="type"
									class="form-control">
									<option
										v-for="type in types"
										:key="type"
										:value="type">
										{{ translate(type.toLowerCase()) }}
									</option>
								</select>
								<template v-if="hasError('type')">
									<span
										v-for="error in errors.errors['type']"
										:key="error"
										class="invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</b-form-group>
							<input-text
								v-if="form.type == 'usage'"
								id="credits"
								:label="translate('credits_amount')"
								:setter-value="form.credits"
								:errors="errors.errors['credits']"
								:required="true"
								type="number"
								@dataChanged="form.credits = $event" />
						</b-col>
					</b-row>
					<b-button
						:disabled="Object.keys(errors.errors).length > 0 || loading"
						class="float-right"
						variant="primary"
						type="submit">
						<template v-if="loading">
							<i class="fa fa-fw fa-spinner fa-pulse" />
							{{ translate('processing') }}
						</template>
						<template v-else>
							{{ translate('submit') }}
						</template>
					</b-button>
				</b-form>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import InputText from '@/components/InputText';

import {
	Credits as messages, Countries, Profile, Sms,
} from '@/translations';
import Country from '@/util/Country';
import Credit from '@/util/Credit';
import { WHITELIST_TYPES } from '@/settings/Credits';

export default {
	name: 'CreditsAdd',
	components: {
		InputText,
	},
	messages: [messages, Countries, Profile, Sms],
	data() {
		return {
			credits: new Credit(),
			country: new Country(),
			countries: [],
			form: {
				mobile_number: null,
				country: '',
				type: 'usage',
				credits: 0,
			},
			types: WHITELIST_TYPES,
			alert: new this.$Alert(),
		};
	},
	computed: {
		errors() {
			return this.credits.data.errors;
		},
		loading() {
			return !!this.credits.data.loading;
		},
	},
	created() {
		this.country.getCountries().then((response) => {
			this.countries = response;
			this.form.country = response[0].attributes.code;
		});
	},
	methods: {
		submit() {
			this.credits
				.addCredits(this.form)
				.then(() => {
					this.alert.toast('success', this.translate('credits_added'));
					this.form.mobile_number = null;
					this.form.credits = 0;
				})
				.catch(() => {
					this.data = this.form;
				});
		},
		hasError(field) {
			if (typeof this.errors.errors[field] !== 'undefined') {
				return true;
			}
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.credits.data.errors.errors[field];
				this.credits.data.errors.errors = {
					...this.errors.errors,
				};
			}
		},
		handleMobileChange() {
			this.clearError('mobile_number');
		},
	},
};
</script>
