import {
	ADD_CREDITS,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Credit {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	// getCredits(options) {
	// 	const { method, endpoint } = CREATE_CREDITS;
	// 	return this.data[method](endpoint, options).then((response) => response).catch((error) => {
	// 		this.errors.record(error);
	// 		return Promise.reject(error);
	// 	});
	// }

	addCredits(options) {
		const { method, endpoint } = ADD_CREDITS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Credit;
